<template>
  <div v-if="list" class="deposit-campaign">
    <div class="deposit-campaign__table">
      <div class="deposit-campaign__table-row deposit-campaign__table-row--head  hide-xs">
        <div class="deposit-campaign__table-campaign">
          {{ $t('deposit_campaign.campaign') }}
        </div>
        <div class="deposit-campaign__table-rest">
          {{ $t('deposit_campaign.remainder') }}
        </div>
        <div class="deposit-campaign__table-amount">
          {{ $t('deposit_campaign.add_amount') }}
        </div>
      </div>
      <div class="deposit-campaign__table-row">
        <div class="deposit-campaign__table-campaign">
          <label class="input__item-checkbox">
            <input
              type="checkbox"
              value="'all'"
              v-model="chooseAll"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ $t('deposit_campaign.select_all') }}
              </span>
            </span>
          </label>
        </div>
      </div>
      <div v-for="item in list" :key="item.id" class="deposit-campaign__table-row">
        <div class="deposit-campaign__table-campaign">
          <div class="deposit-campaign__caption  hide-lg  hide-md  hide-sm">{{ $t('deposit_campaign.campaign') }}</div>
          <label class="input__item-checkbox">
            <input
              type="checkbox"
              :value="item.id"
              @change="changedSelectedCampaigns"
              v-model="chooseCampaign"
            >
            <span class="input__item-checkbox__check">
              <img src="@/assets/icons/icon-done.svg">
            </span>
            <span class="input__item-checkbox__info">
              <span class="input__item-checkbox__title">
                {{ item.id }}
              </span>
              <span class="input__item-checkbox__desc">
                {{ item.name }}
              </span>
            </span>
          </label>
        </div>
        <div class="deposit-campaign__table-rest">
          <div class="deposit-campaign__caption  hide-lg  hide-md  hide-sm">{{ $t('deposit_campaign.remainder') }}</div>
          <div class="deposit-campaign__table-rest-text">{{ numberWithSpacesCeil(item.total_limit) }} {{$t('currency.currency_symbol')}}</div>
        </div>
        <div class="deposit-campaign__table-amount">
          <div class="deposit-campaign__caption  hide-lg  hide-md  hide-sm">{{ $t('deposit_campaign.add_amount') }}</div>
          <DepositAmount :campaign-id="item.id" :currency="currency" :addAccount="addAccount" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DepositAmount from "@/components/ad/DepositAmount";
import numberWithSpaces from '@/mixin/numberWithSpaces'

export default {
  name: "DepositCampaign",
  components: {
    DepositAmount,
  },
  mixins: [numberWithSpaces],
  props: {
    list: {
      type: Array,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    addAccount: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      chooseAll: false,
      chooseCampaign: [],
      currency_symbols: {
        'RUB': '₽',
        'USD': '$',
        'EUR': '€',
        'PLN': 'zł',
      }
    }
  },
  watch: {
    chooseAll (val) {
      if (val) {
        if (this.list && this.list.length) {
          this.list.forEach((el) => {
            this.chooseCampaign.push(el.id)
          })
        }
      } else {
        this.chooseCampaign = []
      }
      this.$store.commit('Balance/setData', { label: 'chooseCampaign', data: this.chooseCampaign })
    },
  },
  methods: {
    changedSelectedCampaigns() {
      this.$store.commit('Balance/setData', { label: 'chooseCampaign', data: this.chooseCampaign })
    },
    resetChosenCampaigns() {
      this.chooseAll = false
    },
  }
}
</script>

<style scoped lang="scss">
  @import "@/assets/styles/helpers/variables";
  @import "@/assets/styles/helpers/mixin";
  .deposit-campaign {
    background: #FFFFFF;
    box-shadow: 0 4px 12px rgba(192, 192, 192, 0.12);
    border-radius: 8px;
    padding: 3rem 2.6rem;

    &__name {
      margin-bottom: 2rem;

      img {
        width: 13rem;
      }
    }

    &__table {

      &-row {
        display: flex;
        align-items: center;
        min-height: 8rem;

        &:nth-of-type(2) {
          min-height: 5rem;
        }

        &--head {
          font-size: 1.6rem;
          line-height: 1.9rem;
          color: #AAAAAA;
          border-bottom: 1px solid rgba(0, 0, 0, 0.15);
          padding-bottom: 1.3rem;
          min-height: auto;

          .deposit-campaign__table-campaign {
            padding-left: 3rem;
          }
        }
      }

      &-campaign {
        width: 32rem;
      }

      &-rest {
        font-size: 1.6rem;
        line-height: 1.9rem;
        text-align: right;
        width: 19rem;
        padding-right: 2rem;

        span {
          color: #AAAAAA;
        }
      }

      &-amount {
        padding-left: 5rem;
      }
    }
  }

  @include below(769px) {
    .deposit-campaign__caption{
      margin-bottom: 5px;
      color: #AAAAAA;
    }
    .deposit-campaign__table-row{
      margin-bottom: 30px;
      flex-wrap: wrap;
      > div{
        text-align: left;
        padding: 0;
      }
    }
    .deposit-campaign__table-rest{
      width: auto;
      margin-right: 30px;
    }
    .deposit-campaign__table-campaign{
        margin-bottom: 10px;
      width: 100%;
    }
    .deposit-campaign__table-rest-text{
      height: 44px;
      display: flex;
      align-items: center;
    }
  }
</style>
